// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import RiseOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/RiseOutlined';
import ClusterOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/ClusterOutlined';
import UserOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/UserOutlined';
import UsergroupAddOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/UsergroupAddOutlined';
import HistoryOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/HistoryOutlined';
import IdcardOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/IdcardOutlined';
import AppstoreAddOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/AppstoreAddOutlined';
import RedEnvelopeOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/RedEnvelopeOutlined';
import FileTextOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/FileTextOutlined';
import RetweetOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/RetweetOutlined';
import ReadOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/ReadOutlined';
import BankOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/BankOutlined';
import CalendarOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/CalendarOutlined';
import FormOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/FormOutlined';
import ApiOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/ApiOutlined';
import BookOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/BookOutlined';
import VideoCameraOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/VideoCameraOutlined';
import MoneyCollectOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/MoneyCollectOutlined';
import MonitorOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/MonitorOutlined';
import SolutionOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/SolutionOutlined';
import MailOutlined from '/var/www/html/intl-erp/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/MailOutlined';
export default { SmileOutlined, RiseOutlined, ClusterOutlined, UserOutlined, UsergroupAddOutlined, HistoryOutlined, IdcardOutlined, AppstoreAddOutlined, RedEnvelopeOutlined, FileTextOutlined, RetweetOutlined, ReadOutlined, BankOutlined, CalendarOutlined, FormOutlined, ApiOutlined, BookOutlined, VideoCameraOutlined, MoneyCollectOutlined, MonitorOutlined, SolutionOutlined, MailOutlined };
