export default function access(initialState) {
  const { currentUser, permissions, hasPermission } = initialState || {};
  if (!currentUser || !currentUser.id) return {};
  const permissionScopes = currentUser.permissionScopes;
  const roles = currentUser.roles?.map?.((r) => r.name || r);
  const isSuperAdmin = roles?.includes?.('superadmin');
  const isAdmin = roles?.includes?.('admin');
  const isHR = roles?.includes?.('hr');
  const isFinance = roles?.includes?.('finance');
  const isOperation = roles?.includes?.('operations');
  const isOperationAdmin = roles?.includes?.('operation_admin');
  const isSales = roles?.includes?.('sales');
  const isMatchMaker = roles?.includes?.('matchmaker');
  const isRecruiter = roles?.includes?.('recruiter');
  const isEduHR = roles?.includes?.('eduHR');
  const isQualityInspector = roles?.includes?.('qualityInspector');
  const isTeacher = roles?.includes?.('teacher');
  const isSalesAdmin = roles?.includes?.('sales_admin');
  const isOperationSupervisor = roles?.includes?.('operation_supervisor');
  const canSales = isSuperAdmin || isAdmin || isSales || isSalesAdmin || isHR;
  const canOrganization = !!permissions?.organization?.length;
  const canViewAllPerformanceData = !!permissions?.data_center?.includes(
    'view-all-teams-performance-raw-data',
  );

  const canAccount = !!permissions?.account?.length;
  const canUpdateRolePermissions = permissionScopes.permission?.editRole?.find(
    (item) => item.scope.name === 'all',
  );
  const canUpdateUserPermissions = permissionScopes.permission?.editUser?.find(
    (item) => item.scope.name === 'all',
  );
  const canViewTeacherReview = !!permissions?.account?.includes('viewTeacherReview');
  const canOperation = !!permissions?.operations?.length;
  const canTeacher = !!permissions?.teacher?.length;
  const canAccessOnboarding = !!permissions?.onboarding?.length;
  const canAccessHR = !!permissions?.hr?.length;
  const canAccessSecretTeacherFeedback = !!permissions?.teacherFeedback?.length;
  const canAssignCourse = !!permissions?.trainingCourse?.includes('assign');
  const canViewAllSalesData = hasPermission({
    route: 'sales',
    function: 'query-sales-data',
    scope: 'all',
  });

  const canViewPartialSalesData = hasPermission({
    route: 'sales',
    function: 'query-sales-data',
    scope: 'partial',
  });

  const canAccessSaleData = canViewAllSalesData || canViewPartialSalesData || isSalesAdmin;
  const canServiceAgreement = isSuperAdmin || isAdmin || isFinance || isSalesAdmin || isSales;

  const canQueryAllFullTimeTeacherSalary = hasPermission({
    route: 'fulltimeTeacherSalary',
    function: 'query-fulltime-teacher-salary',
    scope: 'all',
  });

  const canQueryPartialFullTimeTeacherSalary =
    hasPermission({
      route: 'fulltimeTeacherSalary',
      function: 'query-fulltime-teacher-salary',
      scope: 'partial',
    }) && currentUser.isFullTimeTeacher === true;

  const canComplaint =
    isAdmin ||
    isSuperAdmin ||
    isRecruiter ||
    isMatchMaker ||
    isQualityInspector ||
    isEduHR ||
    isOperation ||
    isOperationAdmin;
  // const canQueryCourseMatch = !!permissions?.courseMatch?.includes('query');
  const canQueryAllCourseMatch = hasPermission({
    route: 'courseMatch',
    function: 'query',
    scope: 'all',
  });
  const canQueryPartialCourseMatch = hasPermission({
    route: 'courseMatch',
    function: 'query',
    scope: 'partial',
  });

  const canQueryCourseMatch = canQueryAllCourseMatch || canQueryPartialCourseMatch;

  //可以看到教师评级任务
  const hasViewAndEditAllPermission = hasPermission({
    route: 'teacher-grade-task',
    function: 'view-edit-teacher-grade-task',
    scope: 'all',
  });

  const hasViewAndEditPartPermission = hasPermission({
    route: 'teacher-grade-task',
    function: 'view-edit-teacher-grade-task',
    scope: 'partial',
  });

  return {
    isSuperAdmin,
    isAdmin,
    isHR,
    isFinance,
    isOperation,
    isOperationAdmin,
    isSales,
    isMatchMaker,
    isRecruiter,
    isEduHR,
    isQualityInspector,
    canSales,
    canOrganization,
    canViewAllPerformanceData,
    canAccount,
    canViewTeacherReview,
    canOperation,
    canTeacher,
    canComplaint,
    canQueryCourseMatch,
    canQueryFullTimeTeacherSalary:
      canQueryAllFullTimeTeacherSalary || canQueryPartialFullTimeTeacherSalary,
    canTeam: isSuperAdmin || isAdmin || isHR,
    canAccountProfile: (profileUser) => {
      if (!profileUser || !profileUser?.id) {
        return permissions?.account?.includes?.('create');
      }
      return profileUser.id === currentUser.id || permissions?.account?.includes?.('update');
    },
    canTeacherProfile: (profileUser) => {
      if (!profileUser || !profileUser?.id) {
        return permissions?.teacher?.includes?.('create');
      }
      return profileUser.id === currentUser.id || permissions?.teacher?.includes?.('update');
    },
    canOperationProfile: (profileUser) => {
      if (!profileUser || !profileUser?.id) {
        return permissions?.operations?.includes?.('create');
      }
      return profileUser.id === currentUser.id || permissions?.operations?.includes?.('update');
    },
    canUpdateRolePermissions,
    canUpdateUserPermissions,
    canStudent:
      isSuperAdmin ||
      isAdmin ||
      isOperation ||
      isOperationAdmin ||
      isMatchMaker ||
      isQualityInspector,
    canPaymentApplication: !!permissions?.paymentApplication?.length,
    canRecruit:
      isSuperAdmin ||
      isAdmin ||
      isHR ||
      isRecruiter ||
      isEduHR ||
      isQualityInspector ||
      canAccessHR,
    canProduct: isSuperAdmin || isAdmin,
    canManageProductBonusScheme: permissions?.product?.includes('manage-bonus-scheme'),
    canReviewRenewalBonus: permissions?.bonus?.includes('review-renewal-bonus'),
    canReviewSettlementBonus: permissions?.bonus?.includes('review-settlement-bonus'),
    canReviewActiveStudentBonus: permissions?.bonus?.includes('review-active-student-bonus'),
    canOverwriteBonusAmount: permissions?.bonus?.includes('overwrite-bonus-amount'),
    canDeleteOperationBonus: permissions.bonus?.includes('delete-bonus'),
    canContract: !!permissionScopes.contract,
    canCreateContract: permissions?.contract?.includes('create-contract'),
    canEditContract: permissions?.contract?.includes('edit-contract'),
    canMail: isSuperAdmin || isAdmin || isOperationAdmin || isOperationAdmin,
    hasTrainingCoursePermission: !!permissions?.trainingCourse?.length,
    hasFinancePermission: !!permissions?.finance?.length,
    hasSalaryPermission: !!permissions?.salary?.length,
    canAudit: isSuperAdmin || isFinance,
    hasWithdrawPermission: !!permissions?.withdraw?.length,
    hasZoomPermission: !!permissions?.zoom?.length,
    canSearchLesson: permissions?.lesson?.includes('query'),
    hasAssignmentPermission: !!permissions?.assignment?.length,
    AssignmentProfile:
      permissions?.assignment?.includes('create') || permissions?.assignment?.includes('update'),
    canMarkAssignment: permissions?.assignment?.includes('mark'),
    hasSchoolPermission: !!permissions?.school?.length,
    CoursePage: isTeacher || permissions?.course?.includes('query'),
    DataCenter: isSuperAdmin || isAdmin,
    Mail: isSuperAdmin || isAdmin || isOperationAdmin,
    canDeleteTrainingCourseRegistration: permissions.trainingCourse?.includes('delete-record'),
    canRegisterTrainingCourse: permissions.trainingCourse?.includes('create-record'),
    canRegisterSavvyUniCourse: isSuperAdmin || isAdmin,
    canCheckOperationBonus: isSuperAdmin || isAdmin || isFinance,
    //可以查看涨薪板块
    canCheckSalaryIncreaseApprovals: permissions.salaryIncreaseApprovals?.includes('update'),
    canAccessOnboarding,
    canAccessSecretTeacherFeedback,
    canQueryPayers: permissions.organization?.includes('query-payers'),
    canCreatePayers: permissions.organization?.includes('create-payers'),
    canUpdatePayers: permissions.organization?.includes('update-payers'),
    canEditCode:
      permissions.organization?.includes('edit-code') && permissions.product?.includes('edit-code'),
    canDeclineOrder: permissions.sales?.includes('decline-order'),
    canAssignCourse,
    canAccessSaleData,
    canEditProductRule: permissions.product?.includes('change-product-rule-toc'),
    canAddCourseToSyllabus: permissions?.schoolSemester?.includes('create-course'),
    canCreateSalesChannel: permissions.sales?.includes('create-channel'),
    canUpdateSalesChannel: permissions.sales?.includes('edit-channel'),
    canCreateOrganization: permissions.organization?.includes('create'),
    canUpdateOrganization: permissions.organization?.includes('update'),
    canDeleteOrganization: permissions.organization?.includes('delete'),
    canRestoreOrganization: permissions.organization?.includes('restore'),
    canCreateLesson: permissions.lesson?.includes('create'),
    canMatchStudentNTeacher: permissions.courseMatch?.includes('create'),
    canConfirmSalaryForOthers: permissions.salary?.includes('proxy-confirmation'),
    canQueryNotice: permissions.notice?.includes('get-all-notice'),
    //可以看到教研任务管理
    canQueryResearchTask:
      (isQualityInspector && isTeacher) ||
      isAdmin ||
      isSuperAdmin ||
      permissions.educationalResearchTasks?.includes('query-all') ||
      permissions.educationalResearchTasks?.includes('update-actualWorkingHours'),
    canViewFirstLesson: permissions.lesson?.includes('viewFirstLesson'),
    canManageOperationAttendance: permissions.employeeAttendance?.includes('manage'),
    canManageOperationBaseCompensation: permissions.salary?.includes(
      'manage-operation-compensation-plan',
    ),
    canOperationPerformance: isSuperAdmin || isHR || isOperationSupervisor,
    canOperationBonus:
      isSuperAdmin || isHR || isOperationAdmin || isOperation || isOperationSupervisor,
    canViewOperationPaystubs:
      isSuperAdmin || isHR || isOperationAdmin || isOperation || isOperationSupervisor,
    canCreateCustomBonus: permissions.bonus?.includes('create-custom-bonus'),
    canServiceAgreement,
    canViewTeacherGradeTasks: hasViewAndEditAllPermission || hasViewAndEditPartPermission,
  };
}
