import Address from '@/components/FormItems/Address';
import FileUpload from '@/pages/Contract/components/Uploads';
import {
  create,
  getAirwallexFormSchema,
  query,
  update,
  validateBeneficiary,
} from '@/services/receivingAccount';
import { countryCodes } from '@/utils/CountryCodes';
import { getErrorMessageFromResponse } from '@/utils/utils';
import {
  ProForm,
  ProFormDatePicker,
  ProFormDependency,
  ProFormGroup,
  ProFormRadio,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from '@ant-design/pro-components';
import { Alert, Button, Image, Input, Modal, Spin, message } from 'antd';
import { validate as validatePostalCode } from 'postal-codes-js';
import { useEffect, useState } from 'react';

const CurrencyToCountry = {
  RMB: 'CN',
  CAD: 'CA',
  USD: 'US',
  GBP: 'GB',
  AUD: 'AU',
};
//更换为Airwallex
const DefaultCurrencyToPlatform = {
  RMB: '薪派',
  CAD: 'Airwallex',
  USD: 'Airwallex',
  GBP: 'Airwallex',
  AUD: 'Airwallex',
};
//更换为Airwallex
const SUPPORT_PLATFORMS = ['薪派', 'Airwallex', 'ADP', '北京办公室'];

// 定义一个函数，根据表单项的类型，返回对应的组件
const renderFormItem = (item) => {
  //pattern
  const rules = item.rule?.pattern;
  //type
  const type = item.rule?.type;

  if (item.field.key === 'account_routing_type1') {
    return (
      <ProFormText
        initialValue={item.field.default}
        name={item.field.key}
        label={item.field.label}
        placeholder={item.field.placeholder}
        tooltip={item.field.description}
        rules={[
          { pattern: rules, message: item.field.label + '格式不正确' },
          { required: item.required },
          { type: type, message: item.field.label + '请输入合法字符' },
        ]}
        hidden
      />
    );
  } else if (item.field.key === 'account_routing_value1') {
    //不同的银行编码对应的提示placeholder不同
    let routingNumberPlaceholder = '';
    if (item.field.label === 'Aba') {
      routingNumberPlaceholder = 'Aba，9位数字，例如：021000021';
    } else if (item.field.label === 'Bsb') {
      routingNumberPlaceholder = 'Bsb，6位数字，例如：082401';
    } else if (item.field.label === 'Sort Code') {
      routingNumberPlaceholder = 'Sort Code，6位数字，例如：609242';
    } else if (item.field.label === 'Routing number') {
      routingNumberPlaceholder =
        'Routing Number 9位银行编码，若不够9位，需要自行在最前面加0,例如：001004832';
    }

    let tooltip = `银行编码，
      可以通过网银查看，在网银里面点开checking，点开account detail，就能看到自己的账户信息了。也可联系自己的开户行询问，也可在支票上寻找。若有问题，请随时联系招聘老师`;

    return (
      <ProFormText
        name={item.field.key}
        label={item.field.label}
        placeholder={routingNumberPlaceholder}
        tooltip={tooltip}
        rules={[
          { pattern: rules, message: item.field.label + '格式不正确' },
          { required: item.required },
          { type: type, message: item.field.label + '请输入合法字符' },
        ]}
      />
    );
  } else if (item.field.key === 'swift_code') {
    let tooltip = `SWIFT codes are made up of four parts:

    1. Bank code

    2. Country code

    3. Location code

    4. branch code (optional)`;

    let placeholder = 'Swift Code，例如：BOFAUS3N';

    return (
      <ProFormText
        name={item.field.key}
        label={item.field.label}
        placeholder={placeholder}
        tooltip={tooltip}
        rules={[
          { pattern: rules, message: item.field.label + '格式不正确' },
          { required: item.required },
          { type: type, message: item.field.label + '请输入合法字符' },
        ]}
      />
    );
  } else if (item.field.key === 'iban') {
    let tooltip = `Each IBAN is comprised of the following codes:

    1. Country code: Each country that participates in the IBAN system has its own country code.

    2. Check digit code: This code allows the sending bank to perform a sanity check on the IBAN and ensure it contains no errors.

    3. Bank identifier code: This code identifies the bank or financial institution where the account is held.

    4. Branch code: Also known as a sort code, this code identifies the bank and branch where the account is held.

    5. Account number: This code identifies the individual bank account, and is unique to each account. `;

    let placeholder = 'IBAN，例如：GB82WEST12345698765432';

    return (
      <ProFormText
        name={item.field.key}
        label={item.field.label}
        placeholder={placeholder}
        tooltip={tooltip}
        rules={[
          { pattern: rules, message: item.field.label + '格式不正确' },
          { required: item.required },
          { type: type, message: item.field.label + '请输入合法字符' },
        ]}
      />
    );
  }
  return (
    <ProFormText
      name={item.field.key}
      label={item.field.label}
      placeholder={item.field.placeholder}
      tooltip={item.field.description}
      rules={[
        { pattern: rules, message: item.field.label + '格式不正确' },
        { required: item.required },
        { type: type, message: item.field.label + '请输入合法字符' },
      ]}
    />
  );
};

const ReceivingAccountForm = ({
  owner,
  paymentMethod,
  currency,
  onFinish,
  isDeleted,
  airwallexPreSetData,
}) => {
  const [form] = ProForm.useForm();

  const [airwallexForm, setAirwallexForm] = useState(null);

  const [links, setLinks] = useState([]);

  const [isPreviewVisible, setPreviewVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  //获取airwallexForm
  const getAirwallexForm = async (airwallexPreSetData) => {
    const data = await getAirwallexFormSchema(airwallexPreSetData);
    if (data) {
      setAirwallexForm(data);
    }
  };

  useEffect(() => {
    if (paymentMethod === 'Airwallex') {
      setLoading(true);
      getAirwallexForm(airwallexPreSetData).then(() => setLoading(false));
    }
  }, [paymentMethod, airwallexPreSetData]);

  return (
    <ProForm
      form={form}
      params={{ owner, currency }}
      request={async (params) => {
        const data = await query(params);
        if (data) {
          data.address = {
            street: data.street,
            city: data.city,
            province: data.province,
            country: data.country,
            county: data.county,
            postalCode: data.postalCode,
          };
          data.owner = owner;
          // if (paymentMethod === 'Airwallex') {
          //   await getAirwallexForm(airwallexPreSetData);
          // }
          if (
            paymentMethod === 'Airwallex' &&
            data.airwallexPayload &&
            Object.keys(data.airwallexPayload).length > 0
          ) {
            if (airwallexPreSetData.payment_method === 'SWIFT')
              data.swift_code = data.airwallexPayload.beneficiary.bank_details.swift_code || '';
            if (['CZ', 'DE', 'LT'].includes(airwallexPreSetData.bank_country_code)) {
              data.iban = data.airwallexPayload.beneficiary.bank_details.iban || '';
            } else {
              data.account_routing_value1 =
                data.airwallexPayload.beneficiary.bank_details.account_routing_value1 || '';
              data.account_number =
                data.airwallexPayload.beneficiary.bank_details.account_number || '';
            }

            data.bank_name = data.airwallexPayload.beneficiary.bank_details.bank_name || '';

            data.account_name = data.airwallexPayload.beneficiary.bank_details.account_name || '';
          }
          setLinks(data.links);
          return data;
        } else {
          let areaCode;
          if (currency === 'CAD') {
            areaCode = '1';
          }
          if (currency === 'RMB') {
            areaCode = '86';
          }
          let platform = DefaultCurrencyToPlatform[currency];
          if (SUPPORT_PLATFORMS.includes(paymentMethod)) {
            platform = paymentMethod;
          }
          return {
            owner,
            currency,
            platform,
            address: {
              country: CurrencyToCountry[currency],
            },
            areaCode,
          };
        }
      }}
      onFinishFailed={(error) => {
        console.log(error);
      }}
      onFinish={async (values) => {
        if (values.currency === 'RMB') {
          values.country = 'CN';
          values.chineseName = values.chineseName.trim();
          values.chinaCitizenId = values.chinaCitizenId.trim();
        }
        if (values.firstName) {
          values.firstName = `${values.firstName[0].toUpperCase()}${values.firstName.substring(1)}`;
        }
        if (values.lastName) {
          values.lastName = `${values.lastName[0].toUpperCase()}${values.lastName.substring(1)}`;
        }
        if (values.address) {
          values.street = values.address.street.trim();
          values.postalCode = values.address.postalCode.toUpperCase().trim();
          values.city = values.address.city;
          values.province = values.address.province;
          values.county = values.address.county;
          values.country = values.address.country;
        }
        if (values.currency === 'CAD') {
          const postalCode = values.address.postalCode.toUpperCase().replace(' ', '');
          values.postalCode = `${postalCode.substring(0, 3)} ${postalCode.substring(3)}`;
        }
        let payload = values;
        if (typeof payload.dateOfBirth === 'object') {
          payload.dateOfBirth = payload.dateOfBirth.toISOString().split('T')[0];
        }

        //如果是Airwallex
        if (values.platform === 'Airwallex') {
          //验证Airwallex数据
          payload = Object.assign({}, values, airwallexPreSetData);

          try {
            const response = await validateBeneficiary(payload);
            if (response.status === 'error') {
              //根据返回的结果调整fields的错误状态
              const errorFields = response.data.data;
              //把这些错误的field的status设置为error
              errorFields.forEach((item) => {
                form.setFields([
                  {
                    name: item,
                    errors: ['请检查数据是否正确！'],
                  },
                ]);
              });
              //如果没有对应的fieds，就给出message提醒
              airwallexForm.some((item) => {
                if (!errorFields.includes(item.field.key)) {
                  message.error('未知字段错误，请联系管理员！');
                  return true;
                }
              });
              return;
            }
          } catch (error) {
            message.error('验证数据失败！');
            return;
          }
        }
        try {
          const cb = !!values.id ? update : create;
          if (values.id) {
            payload.status = 'update';
          }
          // const res = await cb(values);
          const res = await cb(payload);
          message.success('保存成功！');
          onFinish?.(res);
        } catch (error) {
          message.error(getErrorMessageFromResponse(error));
        }
      }}
      submitter={{
        render: (_, dom) => (isDeleted ? <></> : dom),
      }}
      validateTrigger="onBlur"
    >
      <ProFormText name="id" hidden />
      <ProFormText name="owner" hidden />
      <ProFormText name="currency" hidden />
      <ProFormText name="platform" hidden />
      {currency !== 'RMB' && (
        <>
          <ProFormGroup>
            <Input.Group compact>
              <ProFormText
                name="firstName"
                label="First Name"
                width="xs"
                rules={[{ required: true }]}
              />
              <ProFormText
                name="lastName"
                label="Last Name"
                width="xs"
                rules={[{ required: true }]}
              />
            </Input.Group>
            <ProFormRadio.Group
              name="gender"
              label="Gender"
              width="xs"
              options={['M', 'F']}
              rules={[{ required: true }]}
            />
            <ProFormDatePicker
              name="dateOfBirth"
              label="Date of Birth"
              rules={[{ required: true }]}
              fieldProps={{
                disabledDate: (currentDate) => {
                  const today = new Date();
                  return currentDate > new Date().setFullYear(today.getFullYear() - 18);
                },
              }}
            />
          </ProFormGroup>
          <ProFormGroup>
            <ProFormText
              name="email"
              label="E-mail"
              width="sm"
              tooltip="Notification emails will be sent here."
              placeholder="to receive notifications"
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid email',
                },
                { required: true },
              ]}
            />
            <Input.Group compact>
              <ProFormSelect
                name="areaCode"
                label="Prefix"
                width="xs"
                placeholder="Area Code"
                rules={[{ required: true }]}
                options={Object.keys(countryCodes).map((elem) => {
                  return {
                    value: elem.toString(),
                    label: `+${elem}`,
                  };
                })}
              />
              <ProFormText
                name="phoneNumber"
                label="Phone Number"
                width="sm"
                placeholder="Phone Number"
                rules={[{ required: true }]}
              />
            </Input.Group>
          </ProFormGroup>
        </>
      )}
      <ProFormDependency name={['platform']}>
        {({ platform }) => {
          if (platform === 'ADP') {
            return (
              <>
                <ProFormGroup>
                  <ProFormText
                    width="sm"
                    name="taxId"
                    label="SIN"
                    required
                    placeholder="发工资和报税都需要SIN"
                    rules={[
                      {
                        async validator(_, value) {
                          return new Promise((resolve, reject) => {
                            if (!value) {
                              reject('Social Insurance Number is required.');
                            }
                            if (!/^\d+$/.test(value)) {
                              reject('Please enter digits only');
                            }
                            if (value.length !== 9) {
                              reject('Please enter 9 digits');
                            }
                            resolve();
                          });
                        },
                      },
                    ]}
                  />
                  <ProFormDependency name={['taxId']}>
                    {({ taxId }) => {
                      const taxIdString = `${taxId}`;
                      return (
                        <ProFormDatePicker
                          width="sm"
                          name="taxIdExpiryDate"
                          label="SIN Expiry Date"
                          placeholder="不会过期就不用填"
                          required={taxIdString.startsWith('9')}
                          rules={[
                            {
                              required: taxIdString.startsWith('9'),
                              message: '9开头的SIN必须填过期日期',
                            },
                          ]}
                          fieldProps={{
                            disabledDate: (currentDate) => {
                              return currentDate < new Date();
                            },
                          }}
                        />
                      );
                    }}
                  </ProFormDependency>
                </ProFormGroup>
                <ProFormGroup>
                  <ProFormText
                    width="sm"
                    name="bankDepositTransit"
                    label="Bank Deposit Transit"
                    required
                    placeholder="(3-digit-Inst#)(5-digit-Transit#)"
                    extra="e.g.: 00011111"
                    rules={[
                      {
                        async validator(_, value) {
                          return new Promise((resolve, reject) => {
                            if (!value) {
                              reject('Bank Deposit Transit is required.');
                            }
                            if (!/^\d+$/.test(value)) {
                              reject('Please enter digits only');
                            }
                            if (value.length !== 8) {
                              reject('Please enter 8 digits');
                            }
                            resolve();
                          });
                        },
                      },
                    ]}
                  />
                  <ProFormText
                    width="sm"
                    name="bankDepositAccountNumber"
                    label="Bank Deposit Account Number"
                    required
                    placeholder="Should be 7 digits"
                    rules={[
                      {
                        async validator(_, value) {
                          return new Promise((resolve, reject) => {
                            if (!value) {
                              reject('Account Number is required.');
                            }
                            if (!/^\d+$/.test(value)) {
                              reject('Please enter digits only');
                            }
                            if (value.length !== 7) {
                              reject('Please enter 7 digits');
                            }
                            resolve();
                          });
                        },
                      },
                    ]}
                  />
                </ProFormGroup>
              </>
            );
          } else if (platform === 'Airwallex') {
            if (airwallexForm) {
              if (loading) return <Spin />;
              return (
                <>
                  {airwallexForm?.map((item) => renderFormItem(item))} {/* upload 凭证 */}
                  <ProForm.Item
                    label={
                      links && links.length > 0 ? (
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <div style={{ marginTop: '6px' }}>更新已上传凭证</div>
                          {links && (
                            <div>
                              <Button
                                type="link"
                                onClick={() => setPreviewVisible(!isPreviewVisible)}
                              >
                                预览已传凭证
                              </Button>
                              <Modal
                                open={isPreviewVisible}
                                title="已传凭证预览"
                                footer={null}
                                onCancel={() => setPreviewVisible(!isPreviewVisible)}
                              >
                                {links.map((link) => (
                                  <Image
                                    alt="example"
                                    style={{ width: '100%' }}
                                    src={`https://savvyuni-intl-erp.oss-accelerate.aliyuncs.com/${link.key}`}
                                  />
                                ))}
                              </Modal>
                            </div>
                          )}
                        </div>
                      ) : (
                        '请上传凭证'
                      )
                    }
                    name="uploads"
                    required
                    rules={[
                      {
                        async validator(_, value) {
                          return new Promise((resolve, reject) => {
                            if (!value && links.length === 0) {
                              reject('请上传凭证！');
                            }
                            resolve();
                          });
                        },
                      },
                    ]}
                  >
                    <FileUpload
                      accept=".png,.jpg,.jpeg"
                      maxCouant={10}
                      beforeUpload={(file) => {
                        const isImage =
                          file.type === 'image/jpeg' ||
                          file.type === 'image/jpg' ||
                          file.type === 'image/png';
                        if (!isImage) {
                          message.error(`请上传图片,${file.name} 不是图片文件`);
                        }
                        return isImage;
                      }}
                    />
                  </ProForm.Item>
                </>
              );
            }
          } else if (platform === 'Hyperwallet') {
            return (
              <ProFormText
                name="accountNumber"
                label="PayPal Account E-mail"
                width="sm"
                tooltip="The email address associated with your PayPal account."
                placeholder="to receive payments"
                rules={[
                  {
                    type: 'email',
                    message: 'Please enter a valid email',
                  },
                  { required: true },
                ]}
              />
            );
          } else {
            return <></>;
          }
        }}
      </ProFormDependency>
      {currency === 'RMB' && (
        <>
          <ProFormSwitch
            name="accountCreated"
            label="已在平台签约"
            rules={[
              {
                required: true,
                validator(_, value) {
                  if (value === true) {
                    return Promise.resolve();
                  }
                  return Promise.reject('请先在平台上完成签约');
                },
              },
            ]}
          />
          <Alert
            type="warning"
            message="请保证平台签约信息和系统信息的统一，否则工资可能无法及时发放。"
          />
          <ProFormGroup>
            <ProFormText
              name="chineseName"
              label="收款人中文名"
              width="sm"
              rules={[{ required: true }]}
              extra="需要和平台记录的信息相同"
            />
            <ProFormText
              name="chinaCitizenId"
              label="收款人身份证号"
              width="md"
              rules={[
                {
                  required: true,
                  validator(_, value) {
                    if (value && value.length === 18) {
                      return Promise.resolve();
                    }
                    return Promise.reject('请填入18位身份证号码');
                  },
                },
              ]}
              extra="需要和平台记录的信息相同"
            />
            <ProFormText
              name="bankAccount"
              label="收款人银行卡号"
              width="md"
              rules={[
                {
                  required: true,
                },
              ]}
              extra="需要和平台记录的信息相同"
            />
            <ProFormText
              name="phoneNumber"
              label="收款人手机号"
              width="sm"
              placeholder="Phone Number"
              rules={[
                {
                  required: true,
                  validator(_, value) {
                    if (value && value.length === 11) {
                      return Promise.resolve();
                    }
                    return Promise.reject('请填入11位中国手机号');
                  },
                },
              ]}
              extra="需要和平台记录的信息相同"
            />
          </ProFormGroup>
        </>
      )}
      {/* {currency !== 'RMB' && ( */}
      <ProForm.Item
        name="address"
        label={currency === 'RMB' ? '收款人地址' : 'Address'}
        width="xl"
        required
        rules={[
          {
            async validator(_, values) {
              if (
                !values ||
                !values.street ||
                !values.province ||
                !values.city ||
                !values.postalCode ||
                !values.country
              ) {
                return Promise.reject('财务需要完整的地址信息才能发出工资');
              }
              if (values.postalCode && values.country) {
                const result = validatePostalCode(values.country, values.postalCode);
                if (result === true) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(result);
                }
              }
            },
          },
        ]}
        validateTrigger="onBlur"
      >
        <Address useChineseLable={currency === 'RMB' ? true : false} />
      </ProForm.Item>
      {/* )} */}
    </ProForm>
  );
};

export default ReceivingAccountForm;
