import { EducationalResearchTaskCategory } from '@/pages/TeachingResearch/service';
import { getUsername } from '@/utils/utils';
import dayjs from 'dayjs';
import lodash from 'lodash';
import { getTeacherReview } from './service';

const TeacherReviewModel = {
  namespace: 'teacherReview',

  state: {
    loading: false,
    teacherData: [],
    tableData: [],
  },

  effects: {
    *query({ payload }, { call, put, select }) {
      yield put({ type: 'updateState', payload: { loading: true } });
      const response = yield call(getTeacherReview, payload);
      if (response) {
        yield put({
          type: 'parseTeacherReview',
          payload: {
            response,
          },
        });
      }
      yield put({ type: 'updateState', payload: { loading: false } });
    },
  },
  reducers: {
    updateState(state, { payload }) {
      return { ...state, ...payload };
    },
    parseTeacherReview(state, { payload }) {
      const teacherData = payload?.response;
      if (teacherData) {
        let educationalResearchTasks = [];
        const complaints = teacherData.teachingcomplaints.map((item) => {
          const {
            complaintReview,
            complaintHandling,
            educationalResearchTask,
            student,
            lesson,
            ...complaintInfo
          } = item;
          if (complaintHandling?.verification) {
            complaintInfo.verification = complaintHandling.verification;
          }
          // 用教研填的投诉原因 覆盖 教务填的投诉
          if (complaintReview?.complaintCausation) {
            complaintInfo.complaintReason = complaintReview.complaintCausation;
          }
          if (student?.fullName) {
            complaintInfo.studentName = student.fullName;
          }
          if (lesson?.name) {
            complaintInfo.lessonName = lesson.name;
          }

          if (item.createdAt) {
            complaintInfo.completedAt = item.createdAt;
          }

          if (item.createdBy) {
            complaintInfo.user = getUsername(item.createdBy);
          }

          return complaintInfo;
        });
        const teacherGrade = teacherData.teacherGrade.sort(
          (a, b) => dayjs(b.createdAt) - dayjs(a.createdAt),
        );
        const lessons = teacherData.lessons.map((item) => {
          if (item.educationalResearchTasks.length) {
            educationalResearchTasks = [
              ...educationalResearchTasks,
              ...item.educationalResearchTasks,
            ];
          }
          return {
            rawDuration: item.rawDuration,
            startAt: item.startAt,
            courseName: item.course?.name,
            studentName: item.student?.fullName,
            rating: item.rating,
          };
        });

        let tableData = lodash.compact(
          educationalResearchTasks.map((item) => {
            if (item.category === EducationalResearchTaskCategory.过课) {
              return {
                ...item,
                lessonName: item.workContent.lessonName,
                studentName: item.workContent.studentName,
                user: getUsername(item.user),
              };
            }
            if (item.category === EducationalResearchTaskCategory.抽查视频审核) {
              return {
                ...item,
                lessonName: item.workContent.lessonName,
                studentName: item.workContent.studentName,
                result: item.workContent.result,
                category: EducationalResearchTaskCategory.抽查视频审核,
                user: getUsername(item.user),
              };
            }
            if (item.category === EducationalResearchTaskCategory.课诉视频审核) {
              return {
                ...item,
                lessonName: item.workContent.lessonName,
                studentName: item.workContent.studentName,
                result: item.workContent.result,
                category: EducationalResearchTaskCategory.课诉视频审核,
                user: getUsername(item.user),
              };
            }
          }),
        );
        let studentFeedbacks = [];
        teacherData.lessons
          .filter((item) => item.studentFeedbacks?.length)
          .forEach((lessonItem) => {
            const lessonName = lessonItem.name;
            const user = lessonItem.student.fullName;
            lessonItem.studentFeedbacks.forEach((feedbackItem) => {
              studentFeedbacks.push({
                category: '学生反馈',
                user,
                //用lesson的时间
                createdAt: feedbackItem.createdAt,
                lessonName,
                studentName: user,
                rating: feedbackItem.rating,
                feedbackContent: feedbackItem.feedbackContent,
              });
            });
          });
        let teacherFeedbacks = [];
        teacherData.lessons
          .filter((item) => item.teacherFeedbacks?.length)
          .forEach((lessonItem) => {
            const lessonName = lessonItem.name;
            const user = lessonItem.student?.fullName;
            lessonItem.teacherFeedbacks.forEach((feedbackItem) => {
              teacherFeedbacks.push({
                ...feedbackItem,
                category: '老师反馈',
                user,
                //用lesson的时间
                createdAt: feedbackItem.createdAt,
                lessonName,
                studentName: user,
              });
            });
          });
        tableData = [
          ...tableData,
          ...studentFeedbacks,
          ...teacherFeedbacks,
          ...complaints.map((item) => ({
            ...item,
            category: '投诉',
          })),
        ];
        tableData.sort((a, b) => dayjs(b.createdAt) - dayjs(a.createdAt));

        const ret = {
          ...state,
          teacherData: {
            lessons,
            id: teacherData.id,
            educationalResearchTasks,
            complaints,
            educationalResearchTasks,
            teacherGrade,
            note: teacherData.note,
            lateAttendanceLessonsData: teacherData.lateAttendanceLessonsData,
            salaryAdjustments: teacherData.salaryAdjustments,
          },
          tableData,
        };
        return ret;
      }
    },
  },
};

export default TeacherReviewModel;
