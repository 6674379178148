import { useEffect } from 'react';
import { notification, Button } from 'antd';
import dayjs from 'dayjs';
import { getSchoolEventNotifications } from './service';

const SchoolEventNotifications = () => {
  const [notifyApi, contextHolder] = notification.useNotification();

  const saveDismiss = (eventId, category) => {
    const dismissedData = JSON.parse(localStorage.getItem('dismissedEventIds') || '[]');
    const newDismiss = dismissedData.filter((item) => item.id !== eventId);
    newDismiss.push({ id: eventId, dismissedLevel: category });
    localStorage.setItem('dismissedEventIds', JSON.stringify(newDismiss));
  };

  useEffect(() => {
    const showNotification = (event, category) => {
      notifyApi.info({
        message: `学校事件提醒`,
        description: (
          <div>
            <div>
              <strong>学校：</strong>
              {event.school.name}
            </div>
            <div>
              <strong>事件：</strong>
              {event.eventNameCn}
            </div>
            <div>
              <strong>日期：</strong>
              {event.startDate}
            </div>
          </div>
        ),
        duration: 0,
        key: event.id,
        btn: (
          <Button
            type="link"
            size="small"
            onClick={() => {
              saveDismiss(event.id, category);
              notifyApi.destroy(event.id);
            }}
          >
            知道了
          </Button>
        ),
      });
    };

    const classifyAndNotify = async () => {
      const res = await getSchoolEventNotifications();
      const dismissedData = JSON.parse(localStorage.getItem('dismissedEventIds') || '[]');
      const now = dayjs().format('YYYY-MM-DD');

      if (res && res.length > 0) {
        res.forEach((event) => {
          const eventDate = dayjs(event.startDate);
          const diffDays = eventDate.diff(now, 'day');
          console.log('diffDays', diffDays);

          let category = null;
          if (event.startDate === now) {
            category = 'today';
          } else if (diffDays > 0 && diffDays <= 14) {
            category = 'twoWeeks';
          } else if (diffDays > 14 && diffDays <= 60) {
            category = 'oneMonth';
          }

          if (!category) return;

          const dismissed = dismissedData.find((item) => item.id === event.id);
          // 如果没有记录 || 当前类别比上次记录的类别更紧急，就提醒
          const categoryPriority = { oneMonth: 1, twoWeeks: 2, today: 3 };
          if (
            !dismissed ||
            categoryPriority[category] > categoryPriority[dismissed.dismissedLevel]
          ) {
            showNotification(event, category);
          }
        });
      }
    };

    classifyAndNotify();
  }, [notifyApi]);

  return <>{contextHolder}</>;
};

export default SchoolEventNotifications;
