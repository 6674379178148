import { request } from '@@/plugin-request';

export async function queryAll(payload) {
  return request('/api/educationalResearchTasks/queryAll', { method: 'get', params: payload });
}
export async function deleteById(payload) {
  return request('/api/educationalResearchTasks/deleteById', { method: 'put', data: payload });
}
export async function transferQualityLessonTask(payload) {
  return request('/api/educationalResearchTasks/transferQualityLessonTask', {
    method: 'put',
    data: payload,
  });
}

export async function deleteQualityLessonTask(payload) {
  return request('/api/educationalResearchTasks/deleteQualityLessonTask', {
    method: 'put',
    data: payload,
  });
}
export const EducationalResearchTaskCategory = {
  过课: '过课',
  抽查视频审核: '抽查视频审核',
  课诉视频审核: '课诉视频审核',
  面试结果反馈: '面试结果反馈',
  教务协助工作: '教务协助工作',
  其他教研工作: '其他教研工作',
  '上课（字数）': '上课（字数)',
  '上课（时长）': '上课（时长)',
  教师评级: '教师评级',
};
